import { factors } from "./math";

const calculateTickAmount = (range: number): number => {
  const facs = factors(range);
  const middleValue = facs.length % 2 === 0 ? facs.length / 2 : facs.length / 2 - 0.5;
  return range / facs[middleValue];
};

export const genericChartData = (
  series: number[],
  minAge: number,
): { options: object; series: { name: string; data: number[] }[] } | void => {
  return {
    options: {
      xaxis: {
        type: "numeric",
        tickAmount: calculateTickAmount(series.length - 1),
        min: (): number => minAge,
        labels: {
          hideOverlappingLabels: true,
          formatter: (value: string): string => {
            return String(Number(value) - 1 + minAge);
          },
        },
      },
      yaxis: [
        {
          labels: {
            formatter: function(val: number): string {
              return val.toFixed(0);
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
      },

      chart: {
        toolbar: {
          show: false,
        },
        shadow: {
          enabled: false,
          color: "#bbb",
          top: 3,
          left: 2,
          blur: 3,
          opacity: 1,
        },
      },
      stroke: {
        width: 3,
        curve: "straight",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientFromColors: ["#FF1F11"],
          gradientToColors: ["#FF1F5A"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
    },
    series: [{ name: "", data: series }],
  };
};
