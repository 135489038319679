import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { getContent } from "content";
import { useIsMobile } from "utils/responsive";

export interface Props {
  label: string;
  value: string;
  isValid: boolean;
  onChange: (value: string) => void;
  className?: string;
  explantion?: string;
  hide?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: "100%",
      maxWidth: "600px",
      cursor: "pointer",
    },
    textField: {
      flexGrow: 2,
    },
    textFieldLabel: {
      transformOrigin: "center",
      fontSize: ({ isMobile }: { isMobile: boolean }): string => (isMobile ? "13px" : "inherit"),
    },
  }),
);

const CenteredTextField: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const { explantion } = props;
  const { hide = false } = props;
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  return (
    <>
      {!hide && (
        <Box display="flex" alignItems="flex-end" className={[classes.wrapper, props.className || ""].join(" ")}>
          <Box m={2}></Box>
          <TextField
            className={[classes.textField].join(" ")}
            error={props.value !== "" && !props.isValid}
            inputProps={{ style: { textAlign: "center" } }}
            InputLabelProps={{
              className: classes.textFieldLabel,
              style: {
                width: "max-content",
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
              },
            }}
            onChange={(event): void => props.onChange(event.target.value)}
            value={props.value || ""}
            label={props.label}
          />
          {explantion && (
            <IconButton size="small" onClick={(): void => setOpenModal(true)} color="primary" aria-label="info">
              <InfoOutlinedIcon />
            </IconButton>
          )}
          {!explantion && <Box m={2}></Box>}
        </Box>
      )}
      <Dialog
        open={openModal}
        onClose={(): void => {
          setOpenModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.label}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{explantion}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => {
              setOpenModal(false);
            }}
            color="primary"
          >
            {getContent("closeCenterTextFieldDialogButtonText")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CenteredTextField;
