import React, { ReactElement } from "react";
import { Typography } from "@material-ui/core";
import { ThemeStyle } from "@material-ui/core/styles/createTypography";

interface FormattedTypographyOptions {
  variant?: ThemeStyle;
  className?: string;
}

export const formatedTypography = (string: string, options: FormattedTypographyOptions): ReactElement => {
  const formattedString = string.replace(/\[\[/g, `<span style='color: #FF1F5A';>`).replace(/\]\]/g, "</span>");
  return (
    <Typography
      variant={options.variant || "h3"}
      className={options.className || ""}
      dangerouslySetInnerHTML={{ __html: formattedString }}
    ></Typography>
  );
};

// Example
