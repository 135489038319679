import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import "styles/base.scss";
import { generateRoutes } from "utils/navigation";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ResponsiveProvider, useDeviceTypes } from "utils/responsive";
import { navItems } from "routes";
import { theme } from "utils/theme";
import { SnackbarProvider } from "notistack";

const App: React.FC = () => {
  const deviceTypes = useDeviceTypes();
  return (
    <SnackbarProvider>
      <Router>
        <ResponsiveProvider value={deviceTypes}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>{generateRoutes(navItems)}</div>
          </ThemeProvider>
        </ResponsiveProvider>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
