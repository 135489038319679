import { SavingsPlanResult, SavingsPlanCalculationParams } from "./../types/index";
import { Assumption, RetirmentBurnDown } from "types";
import { range } from "utils/iterators";

const MAX_ITERATIONS = 100;

export const generateYearlyExpenditure = (
  decadeExpenditure: Record<number, number>,
  ageRetired: number,
  ageWillDie: number,
): number[] => {
  let age = ageRetired;
  const vector = [];
  while (age < ageWillDie) {
    age += 1;
    vector.push(decadeExpenditure[Math.floor(age * 0.1)] * 12);
  }
  return vector;
};

export function calculateAmountNeededToRetire(assumptions: Assumption): RetirmentBurnDown | void {
  let initalBalance = assumptions.yearlyExpenditureVector.reduce((acc, cur) => acc + cur, 0);
  let lastCut = initalBalance;
  for (let _ = 0; _ < MAX_ITERATIONS; _++) {
    const yearlyExpenseVector: number[] = [];
    const yearlyInterestVector: number[] = [];
    const balanceVector: number[] = [];
    let balance = initalBalance;
    range(0, assumptions.ageWillDie - assumptions.ageRetired - 1).forEach((index) => {
      const yearlyExpense = assumptions.yearlyExpenditureVector[index];
      const yearlyInterest =
        (balance - yearlyExpense / 2) * (assumptions.interestRateOnPension - assumptions.inflation);
      balance -= yearlyExpense * (1 + assumptions.taxOnPension) - yearlyInterest;
      yearlyExpenseVector.push(yearlyExpense);
      yearlyInterestVector.push(yearlyInterest);
      balanceVector.push(balance);
    });
    if (balance > 0 && balance < 100) {
      return {
        initalBalance,
        balanceVector,
        yearlyExpenseVector,
        yearlyInterestVector,
      };
    }
    lastCut = lastCut / 2;
    if (balance < 0) {
      initalBalance = initalBalance + lastCut;
    } else if (balance > 100) {
      initalBalance = initalBalance - lastCut;
    }
  }
  throw Error("Too few operations to calculate retirement plan");
}

const calculateSavingsBalance = (params: SavingsPlanCalculationParams, monthlyPayment: number): number => {
  const { amountInPensionPot, pensionInterestRate, inflation } = params;
  const interestRate = pensionInterestRate - inflation;
  const yearsToRetirement = params.retirementAge - params.currentAge;
  const balanceOnPrincipal = amountInPensionPot * Math.pow(1 + interestRate / 12, 12 * yearsToRetirement);
  const balanceOnMonthlyPayments =
    (monthlyPayment * (Math.pow(1 + interestRate / 12, 12 * yearsToRetirement) - 1)) / (interestRate / 12);
  return +(balanceOnPrincipal + balanceOnMonthlyPayments).toFixed(2);
};

export function calculateSavingsPlan(params: SavingsPlanCalculationParams): SavingsPlanResult | void {
  const { targeAmount, retirementAge, currentAge } = params;
  const yearsToRetirement = params.retirementAge - params.currentAge;
  let monthlyPayment = targeAmount / (yearsToRetirement * 12);
  let lastCut = monthlyPayment;
  for (let _ = 0; _ < MAX_ITERATIONS; _++) {
    const finalAmount = calculateSavingsBalance(params, monthlyPayment);
    lastCut = lastCut / 2;
    if (Math.abs(finalAmount - targeAmount) < 1) {
      const roundedMonthlyPayment = +monthlyPayment.toFixed(2);
      return {
        balanceVector: range(currentAge, retirementAge).map((age) =>
          calculateSavingsBalance({ ...params, retirementAge: age }, roundedMonthlyPayment),
        ),
        monthlyPayment: roundedMonthlyPayment,
      };
    } else if (finalAmount - targeAmount < 0) {
      monthlyPayment = monthlyPayment + lastCut;
    } else if (finalAmount - targeAmount > 1) {
      monthlyPayment = monthlyPayment - lastCut;
    }
  }
}
