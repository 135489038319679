import { buildNavTree, NavItem, NavTreeItem } from "utils/navigation";
import RetirementPlan from "pages/RetirementPlan/RetirementPlan";

const navigationTree: NavTreeItem[] = [
  {
    path: "/",
    id: "retirement_plan",
    defaultDisplayName: "Retirement Plan",
    component: RetirementPlan,
    children: [],
  },
];

export const navItems: NavItem[] = buildNavTree(navigationTree);
