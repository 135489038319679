import * as t from "io-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { fold } from "fp-ts/lib/Either";
import { reporter } from "io-ts-reporters";
import { ApiErrorsValidator, ApiErrors, ApiError, JSONParsingError, ApplicationError } from "store/models/errors";

export const parseJSON = (response: Response): Promise<Record<string, unknown>> => {
  return response.json();
};

export const mockRequest = (url: string, requestsToMock: [RegExp, () => Response][]): Promise<Response> | undefined => {
  let response: Response | undefined;
  requestsToMock.some((requestMatcher) => {
    if (requestMatcher[0].test(url)) {
      response = requestMatcher[1]();
      return true;
    }
    return false;
  });
  if (response) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 1000);
    });
  }
};

export const validateJson = <T, O, I>(validator: t.Type<T, O, I>, input: I): Promise<T> => {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      () => {
        const error: JSONParsingError = {
          key: "json_parsing_error",
          createdAt: new Date(Date.now()),
          metadata: { reasons: reporter(result) },
        };
        return Promise.reject(new ApplicationError(error));
      },
      (value: T) => Promise.resolve(value),
    ),
  );
};

export const validateJsonSync = <T, O, I>(validator: t.Type<T, O, I>, input: I): T => {
  const result = validator.decode(input);
  if (result._tag === "Left") {
    const error: JSONParsingError = {
      key: "json_parsing_error",
      createdAt: new Date(Date.now()),
      metadata: { reasons: reporter(result) },
    };
    throw new ApplicationError(error);
  } else {
    return result.right;
  }
};

export const generateRequestId = (requestName: string, resourceId: string): string => `${requestName}_${resourceId}`;

export const checkStatus = (response: Response): Response | Promise<Response> => {
  if (response.status >= 400 && response.status < 500) {
    // For 4xx errors we assume the server is returning a useable error
    return response
      .json()
      .then((json) => {
        json.createdAt = new Date(Date.now()).toISOString();
        return validateJson(ApiErrorsValidator, json);
      })
      .then((validatedJson: ApiErrors) => {
        throw new ApiError(validatedJson);
      });
  } else if (response.status >= 500) {
    // For 5xx errors we assume the server has raised an error it was not able to handle
    throw new ApiError({ key: "server_error", createdAt: new Date(Date.now()) });
  }
  return response;
};
