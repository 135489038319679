import { UPDATE_API_REQUEST } from "../../constants/index";
import { createReducer } from "typesafe-actions";
import { ApiRequestState, ApiRequestStates } from "types/store";
import { generateRequestId } from "../../utils/api";

export const initialState: ApiRequestState = {};

export const reducer = createReducer(initialState).handleAction(UPDATE_API_REQUEST, (state, action) => {
  return {
    ...state,
    [action.payload.id]: action.payload.state,
  };
});

export const isRequestLoading = (state: ApiRequestState, requestName: string, id: string): boolean =>
  (state[generateRequestId(requestName, id)] || {}).state === "loading";

export const apiRequestState = (state: ApiRequestState, requestName: string, id: string): ApiRequestStates =>
  state[generateRequestId(requestName, id)];
