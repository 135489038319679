import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import pigggImg from "assets/piggy.png";

export interface Props {
  className: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      width: "200px",
      height: "200px",
      borderRadius: "50%",
      backgroundColor: "white",
      overflow: "hidden",
    },
    wrapper: {
      position: "relative",
      // overflow: "hidden",
      // width: "100%",
      transform: "translateZ(0)",
      zIndex: 1,
      height: "100%",
    },
    img: {
      maxWidth: "80%",
      height: "80%",
    },
    video: {
      borderRadius: "50%",
      width: "200px",
      height: "200px",
      overflow: "hidden",
    },
  }),
);

const Logo: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={[classes.logo, props.className].join(" ")}
    >
      <video className={classes.video} width="300" height="200" poster={pigggImg} autoPlay loop muted playsInline>
        <source src="videos/piggyAnimation.mp4" type="video/mp4" />
      </video>
    </Box>
  );
};

export default Logo;
