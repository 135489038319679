import { ApiError, ApplicationError, ErrorModel } from "store/models/errors";
// import "whatwg-fetch";
import fetch from "unfetch";
import { mockRequest, checkStatus, parseJSON, validateJson } from "utils/api";
import { Dispatch, Middleware } from "redux";
import { API_REQUEST } from "../../constants";
import { StoreState, ApiRequestAction, AllActions } from "types/store";
import { updateApiRequest } from "store/actions/apiRequests";
import { ApiErrors } from "store/models/errors";

export const apiMiddleware = (
  requestsToMock: [RegExp, () => Response][] | never[] = [],
): Middleware<{}, StoreState, Dispatch> => (api) => (next) => (action: AllActions): unknown => {
  next(action);
  if (!action.type || action.type !== API_REQUEST) {
    return;
  }
  const apiRequestAction = action as ApiRequestAction<unknown>;

  const API_HOST = process.env.REACT_APP_API_HOST;

  const { meta } = apiRequestAction;
  const { dispatch, getState } = api;

  let response: undefined | Promise<Response>;

  dispatch(updateApiRequest(meta.id, { state: "loading" }));

  if (["test", "development"].includes(process.env.NODE_ENV)) {
    response = mockRequest(meta.url, requestsToMock);
  }
  if (response === undefined) {
    response = fetch(`${API_HOST}${meta.url}`, {
      method: meta.method || "GET",
      signal: meta.signal,
      body: JSON.stringify(meta.body),
      headers: {
        "Content-Type": "application/json",
        ...meta.headers,
      },
    });
  }
  return response
    .then(checkStatus)
    .then(parseJSON)
    .then((json) => {
      const result = validateJson(meta.validator, json);
      return result;
    })
    .then((model) => {
      if (meta.onSuccessAction) {
        dispatch(updateApiRequest(meta.id, { state: "success" }));
        meta.onSuccessAction(model)(dispatch, getState);
      }
      return model;
    })
    .catch((error) => {
      console.log(error);
      const genericError: ApiErrors = { key: "generic_error", createdAt: new Date(Date.now()) };
      const handleError = (apiError: ErrorModel): void => {
        dispatch(updateApiRequest(meta.id, { state: "error", meta: { error: apiError } }));
        if (meta.onErrorAction) {
          meta.onErrorAction(apiError)(dispatch, getState);
        }
      };
      if (error instanceof ApiError || error instanceof ApplicationError) {
        handleError(error.error);
      } else {
        handleError(genericError);
      }
    });
};

export default apiMiddleware;
