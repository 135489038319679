import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { reducer as apiRequestReducer } from "store/reducers/apiRequests";
import { StoreState, AllActions } from "types/store";
import thunk from "redux-thunk";
import apiMiddleware from "./middleware/api";
import mockedRequests from "./middleware/mockedRequests";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose; //eslint-disable-line
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const reducers = combineReducers<StoreState>({
  apiRequests: apiRequestReducer,
});

export const store = createStore<StoreState, AllActions, unknown, null>(
  reducers,
  composeEnhancers(applyMiddleware(thunk, apiMiddleware(mockedRequests))),
);
