import { createMuiTheme } from "@material-ui/core";

export const COLORS = {
  "dark-green": "#274912",
  "light-green": "#668f71",
  "extra-light-green": "#a5bcaa",
  "lime-green": "#aac47f",
  "dark-grey": "#515962",
  "light-grey": "#b4b4b9",
  "extra-light-grey": "#dedfe3",
  "light-brown": "#ddd4c1",
};

export const COLORS_VALUES = Object.values(COLORS);

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1E2A78",
    },
    secondary: {
      main: "#FF1F5A",
    },
    background: {
      paper: "#fff",
      default: "#F9F9FC",
    },
  },
  typography: {
    fontFamily: [
      "futura",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
