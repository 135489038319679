import * as t from "io-ts";
import { DateType } from "utils/validatorTypes";

// Api Errors

export const UnknownApiErrorValidator = t.intersection([
  t.type({
    key: t.string,
    createdAt: DateType,
  }),
  t.partial({
    metadata: t.record(t.string, t.unknown),
  }),
]);
export type UnknownApiError = t.TypeOf<typeof UnknownApiErrorValidator>;

export const SimpleErrorsValidator = t.intersection([
  t.type({
    key: t.union([t.literal("generic_error"), t.literal("server_error")]),
    createdAt: DateType,
  }),
  t.partial({
    metadata: t.record(t.string, t.unknown),
  }),
]);

export const ApiErrorsValidator = t.union([UnknownApiErrorValidator, SimpleErrorsValidator]);

export type ApiErrors = t.TypeOf<typeof ApiErrorsValidator>;

// Application Errors

export interface JSONParsingError {
  key: "json_parsing_error";
  createdAt: Date;
  metadata: {
    reasons: string[];
  };
}

// All Errors

export type ErrorModel = ApiErrors | JSONParsingError;

// Javascript Error Objects

export class ApiError extends Error {
  error: ApiErrors;

  constructor(error: ApiErrors) {
    super(error.key);
    this.error = error;
  }
}

export class ApplicationError<T extends ErrorModel> extends Error {
  error: T;

  constructor(error: T) {
    super(error.key);
    this.error = error;
  }
}
