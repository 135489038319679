const strings = {
  "retirementAgeTextfieldPlacholder": "When do you want to retire?",
  "decadeMonthlyIncomeFieldPlacholder": "Expected monthly income in your {0}'s",
  "calculateBurnDownButtonText": "Calculate",
  "deathAgeInputLabelText": "Max age you will live to",
  "interestOnPensionInputLabelText": "Interest on Pension (as %)",
  "taxOnPensionIncomeInputLabelText": "Tax on pension (as %)",
  "inflationInputLabelText": "Inflation (as %)",
  "amountNeededForRetirementLable": "You will need {0} by the time you are {1}",
  "otherAssumptionsTitle": "Other Assumptions",
  "aboutLabel":
    "Created by {0}@wilhelmvdwalt{1} as a weekend project in 2019. I built this app to figure out how much I would need to retire at different ages. This has helped me set various goals and priorities. I hope you find it useful. Please note: This is not financial advice. ",
  "expectedIncomeTitle": "Expected Income",
  "closeCenterTextFieldDialogButtonText": "Got it",
  "retirmentAgeInputExplanation":
    "This can be the age you plan to retire in the traditional sense (i.e. stop working entirely). However this can also be the age you wish to no longer be dependent on your income.",
  "expectedIncomeExplanation":
    "This is an estimation for how much monthly income you expect (after tax) in a given decade of your life. For example, if you expect to be taking an income of £4000 per month when you are 65 then put 4000 in the field asking for your monthly income in your 60's.",
  "expectedAgeYouWillDieExplanation":
    "The life expectancy in the UK is 80 years. However more and more people are living older than 110. This is the maximum age you realistically think you will live to. Remember that the algorithm aims to have no money left at this age, so be conservative in your choice.",
  "inflationInputExplation":
    "Inflation is a quantitative measure of the rate at which the average price level of a basket of selected goods and services in an economy increases over a period of time. Inflation in the UK currently is around 1.7%",
  "taxOnPensionIncomeExplanation":
    "This is the amount you will be taxed on the income you get from your retirement fund. This can be quite tricky to work out. Currenlty in the UK you get taxed on 75% of the income you recieve from your pension (excluding your personal allowance). If your monthly income is between 3k and 10k, stick with the default provided.",
  "interestOnPensionExplanation": "This is the amount of interest you earn per year on your pension pot.",
  "interestOnPensionInputText": "Interest on Pension",
  "taxOnPensionIncomeInputText": "Tax paid on pension income",
  "howMuchDoYouNeedToSave": "How much must you save a month to achieve this",
  "howOldAreYouNowLabelText": "How old are you now",
  "howMuchIsInYourPensionNowLabelText": "How much is in your pension pot now",
  "monthlySavingsExplanation": "You need to be saving {0} a month",
};

export default strings;
