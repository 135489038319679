import React, { ReactElement } from "react";
import { WithStyles, createStyles, Box, Typography, Theme, withStyles } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import Logo from "./Logo";
import { getContent, parse } from "content";
import CenteredTextField from "components/CenteredTextField";
import { range } from "utils/iterators";
import { Assumption, RetirmentBurnDown, SavingsPlanCalculationParams, SavingsPlanResult } from "types";
import { formatedTypography } from "utils/text";
import { generateYearlyExpenditure, calculateAmountNeededToRetire, calculateSavingsPlan } from "retirmentAlgos/general";
import Chart from "react-apexcharts";
import { ResponsiveContext } from "utils/responsive";
import profilePic from "assets/profile.jpg";
import { genericChartData } from "utils/charts";

interface ValidityIndex {
  retirementAge: boolean;
  deathAge: boolean;
  inflation: boolean;
  currentPensionPotSize: boolean;
  currentAge: boolean;
  taxOnPensionIncome: boolean;
  interestOnPension: boolean;
  decadeExpenditure: boolean;
  baseInputsOverall: boolean;
  savingsInputOverall: boolean;
  overall: boolean;
}

const yearToDecade = (year: number): number => {
  return Math.floor(year / 10);
};

const textToNumber = (text: string): number | void => {
  const parsed = Number(text);
  if (Number.isNaN(parsed) || text === "") {
    return;
  }
  return parsed;
};

interface StoreProps {}

interface DispatchProps {}

interface ComponentState {
  openModal: boolean;
  retirmentAgeText: string;
  deathAgeText: string;
  interestOnPensionText: string;
  inflationText: string;
  currentAgeText: string;
  currentPensionPotSizeText: string;
  taxOnPensionIncomeText: string;
  decadeMonthlyIncome: Record<number, string>;
}

interface ParentProps extends RouteComponentProps<{}> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (theme: Theme): any => {
  const itemWith = {
    width: "100%",
    maxWidth: "600px",
  };
  return createStyles({
    sectionSpacing: {
      margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
    profilePic: {
      maxWidth: "80px",
      width: "15%",
      borderRadius: "50%",
    },
    title: {
      fontWeight: "bold",
    },
    customButton: {
      background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      color: "white",
    },
    customButtonDisabled: {
      background: theme.palette.grey[100],
    },
    itemSpacing: {
      margin: theme.spacing(1),
    },
    textField: {
      ...itemWith,
    },
    item: {
      ...itemWith,
    },
    transformOverride: {
      transformOrigin: "center",
    },
  });
};

type Props = StoreProps & DispatchProps & ParentProps & WithStyles<typeof styles>;

export class RetirmentPlanPage extends React.Component<Props, ComponentState> {
  static contextType = ResponsiveContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      retirmentAgeText: "",
      deathAgeText: "110",
      inflationText: "1.7",
      currentPensionPotSizeText: "",
      currentAgeText: "",
      taxOnPensionIncomeText: "20",
      interestOnPensionText: "5",
      decadeMonthlyIncome: {},
    };
  }

  currentPensionPotSize(): number | void {
    return textToNumber(this.state.currentPensionPotSizeText);
  }

  currentAge(): number | void {
    return textToNumber(this.state.currentAgeText);
  }

  retirementAge(): number | void {
    return textToNumber(this.state.retirmentAgeText);
  }

  deathAge(): number | void {
    return textToNumber(this.state.deathAgeText);
  }

  inflation(): number | void {
    const value = textToNumber(this.state.inflationText);
    if (value) {
      return value / 100;
    }
    return value;
  }
  taxOnPensionIncome(): number | void {
    const value = textToNumber(this.state.taxOnPensionIncomeText);
    if (value) {
      return value / 100;
    }
    return value;
  }
  interestOnPension(): number | void {
    const value = textToNumber(this.state.interestOnPensionText);
    if (value) {
      return value / 100;
    }
    return value;
  }

  decadeExpenditure(): Record<number, number> {
    return this.decades().reduce((acc, cur) => ({ ...acc, [cur]: Number(this.state.decadeMonthlyIncome[cur]) }), {});
  }

  savingsPlanCalcualtionParams(): SavingsPlanCalculationParams | void {
    const retirementAge = this.retirementAge();
    const currentAge = this.currentAge();
    const inflation = this.inflation();
    const amountInPensionPot = this.currentPensionPotSize();
    const interestOnPension = this.interestOnPension();
    const validityIndex = this.validateInputs();
    const results = this.retirmentPlanResults();
    if (
      retirementAge &&
      currentAge &&
      currentAge < retirementAge &&
      inflation &&
      results &&
      amountInPensionPot &&
      interestOnPension &&
      validityIndex.savingsInputOverall
    ) {
      return {
        retirementAge,
        currentAge,
        amountInPensionPot,
        targeAmount: results.initalBalance,
        inflation,
        pensionInterestRate: interestOnPension,
      };
    }
  }

  assumptions(): Assumption | void {
    const retirementAge = this.retirementAge();
    const deathAge = this.deathAge();
    const inflation = this.inflation();
    const taxOnPensionIncome = this.taxOnPensionIncome();
    const interestOnPension = this.interestOnPension();
    const validityIndex = this.validateInputs();
    if (
      retirementAge &&
      deathAge &&
      deathAge > retirementAge &&
      inflation &&
      taxOnPensionIncome &&
      interestOnPension &&
      validityIndex.decadeExpenditure
    ) {
      const yearlyExpenditureVector = generateYearlyExpenditure(this.decadeExpenditure(), retirementAge, deathAge);

      return {
        ageRetired: retirementAge,
        ageWillDie: deathAge,
        inflation,
        yearlyExpenditureVector,
        taxOnPension: taxOnPensionIncome,
        interestRateOnPension: interestOnPension,
      };
    }
  }

  decades(): number[] {
    const retirementAge = this.retirementAge();
    const deathAge = this.deathAge();
    if (retirementAge && deathAge && deathAge > retirementAge) {
      return range(yearToDecade(retirementAge), yearToDecade(deathAge));
    }
    return [];
  }

  retirmentPlanResults(): RetirmentBurnDown | void {
    const assumptions = this.assumptions();
    if (assumptions) {
      return calculateAmountNeededToRetire(assumptions);
    }
  }

  savingsPlanResults(): SavingsPlanResult | void {
    const params = this.savingsPlanCalcualtionParams();
    if (params) {
      return calculateSavingsPlan(params);
    }
  }

  retirementBurnDownChartData(): { options: object; series: { name: string; data: number[] }[] } | void {
    const result = this.retirmentPlanResults();
    const retirementAge = this.retirementAge();
    if (result && retirementAge) {
      return genericChartData([result.initalBalance].concat(result.balanceVector), retirementAge);
    }
  }

  savingsPlanChartData(): { options: object; series: { name: string; data: number[] }[] } | void {
    const result = this.savingsPlanResults();
    const currentAge = this.currentAge();
    if (result && currentAge) {
      return genericChartData(result.balanceVector, currentAge);
    }
  }

  validateInputs(): ValidityIndex {
    const retirementAge = this.retirementAge();
    const deathAge = this.deathAge();
    const inflation = this.inflation();
    const taxOnPensionIncome = this.taxOnPensionIncome();
    const interestOnPension = this.interestOnPension();
    const decadeExpenditure = this.decadeExpenditure();
    const currentAge = this.currentAge();
    const currentPensionPotSize = this.currentPensionPotSize();
    const validityIndex = {
      retirementAge: retirementAge !== undefined,
      deathAge: deathAge !== undefined,
      inflation: inflation !== undefined,
      currentAge: currentAge !== undefined,
      currentPensionPotSize: currentPensionPotSize !== undefined,
      taxOnPensionIncome: taxOnPensionIncome !== undefined,
      decadeExpenditure: Object.values(decadeExpenditure).every((value) => !Number.isNaN(value) && value > 0),
      interestOnPension: interestOnPension !== undefined,
    };
    const baseInputsOverall =
      [validityIndex.retirementAge, validityIndex.decadeExpenditure].every((isValid) => isValid) &&
      deathAge > retirementAge;
    const savingsInputOverall = validityIndex.currentPensionPotSize && validityIndex.currentAge;
    return {
      ...validityIndex,
      baseInputsOverall,
      savingsInputOverall,
      overall: Object.values(validityIndex).every((isValid) => isValid) && baseInputsOverall && savingsInputOverall,
    };
  }

  render(): ReactElement {
    const { classes } = this.props;
    const { retirmentAgeText } = this.state;
    const validityIndex = this.validateInputs();
    const retirementBurnDownChartData = this.retirementBurnDownChartData();
    const savingsPlanResults = this.savingsPlanResults();
    const savingsPlanChartData = this.savingsPlanChartData();
    const retirmentPlanResults = this.retirmentPlanResults();
    const deviceTypes = this.context;

    return (
      <>
        <Box display="flex" flexDirection="column" alignItems="center" mx={1}>
          {formatedTypography("How much do you need to [[retire]]?", {
            variant: "h4",
            className: [classes.sectionSpacing, classes.title].join(" "),
          })}
          <Logo className={classes.sectionSpacing} />
          <CenteredTextField
            isValid={validityIndex.retirementAge}
            className={classes.itemSpacing}
            explantion={getContent("retirmentAgeInputExplanation")}
            value={retirmentAgeText}
            label={getContent("retirementAgeTextfieldPlacholder")}
            onChange={(value: string): void => {
              this.setState({ retirmentAgeText: value }, () => {
                const retirementAge = Number(value);
                const deathAge = this.deathAge();
                if (!Number.isNaN(retirementAge) && deathAge && retirementAge < deathAge) {
                  const newDecadeMonthlyIncome = range(yearToDecade(retirementAge), yearToDecade(deathAge)).reduce(
                    (acc, cur) => ({ ...acc, [cur]: "" }),
                    {},
                  );
                  this.setState({
                    decadeMonthlyIncome: { ...newDecadeMonthlyIncome, ...this.state.decadeMonthlyIncome },
                  });
                }
              });
            }}
          />
          <CenteredTextField
            hide={!validityIndex.retirementAge}
            isValid={validityIndex.deathAge}
            className={classes.itemSpacing}
            explantion={getContent("expectedAgeYouWillDieExplanation")}
            value={this.state.deathAgeText}
            label={getContent("deathAgeInputLabelText")}
            onChange={(newValue: string): void => {
              this.setState({ deathAgeText: newValue });
            }}
          />
          {this.decades().length > 0 && (
            <Typography className={[classes.sectionSpacing, classes.title].join(" ")} variant="h6">
              {getContent("expectedIncomeTitle")}
            </Typography>
          )}
          {this.decades().map((decade) => {
            return (
              <CenteredTextField
                isValid={textToNumber(this.state.decadeMonthlyIncome[decade]) !== undefined}
                className={classes.itemSpacing}
                explantion={getContent("expectedIncomeExplanation")}
                key={decade}
                value={this.state.decadeMonthlyIncome[decade]}
                label={parse(getContent("decadeMonthlyIncomeFieldPlacholder"), `${decade.toString()}0`)}
                onChange={(newValue: string): void => {
                  this.setState({
                    decadeMonthlyIncome: { ...this.state.decadeMonthlyIncome, [decade]: newValue },
                  });
                }}
              />
            );
          })}
          {validityIndex.baseInputsOverall && (
            <Typography className={[classes.sectionSpacing, classes.title].join(" ")} variant="h6">
              {getContent("otherAssumptionsTitle")}
            </Typography>
          )}
          <CenteredTextField
            hide={!validityIndex.baseInputsOverall}
            isValid={validityIndex.interestOnPension}
            explantion={getContent("interestOnPensionExplanation")}
            className={classes.itemSpacing}
            value={this.state.interestOnPensionText}
            label={getContent("interestOnPensionInputText")}
            onChange={(newValue: string): void => {
              this.setState({ interestOnPensionText: newValue });
            }}
          />
          <CenteredTextField
            hide={!validityIndex.baseInputsOverall}
            className={classes.itemSpacing}
            explantion={getContent("taxOnPensionIncomeExplanation")}
            isValid={validityIndex.taxOnPensionIncome}
            value={this.state.taxOnPensionIncomeText}
            label={getContent("taxOnPensionIncomeInputText")}
            onChange={(newValue: string): void => {
              this.setState({ taxOnPensionIncomeText: newValue });
            }}
          />
          <CenteredTextField
            hide={!validityIndex.baseInputsOverall}
            className={classes.itemSpacing}
            explantion={getContent("inflationInputExplation")}
            value={this.state.inflationText}
            isValid={validityIndex.inflation}
            label={getContent("inflationInputLabelText")}
            onChange={(newValue: string): void => {
              this.setState({ inflationText: newValue });
            }}
          />
          {retirementBurnDownChartData && retirmentPlanResults && (
            <>
              {formatedTypography(
                parse(
                  getContent("amountNeededForRetirementLable"),
                  `[[${retirmentPlanResults.initalBalance.toLocaleString("en")}]]`,
                  `[[${retirmentAgeText}]]`,
                ),
                {
                  variant: "h6",
                  className: [classes.sectionSpacing, classes.title].join(" "),
                },
              )}
              <Chart
                options={retirementBurnDownChartData.options}
                series={retirementBurnDownChartData.series}
                type="line"
                width={deviceTypes.includes("mobile") ? "110%" : "600px"}
                height={320}
              />
              {/* How much must you save */}
              <Typography className={[classes.sectionSpacing, classes.title].join(" ")} variant="h6">
                {getContent("howMuchDoYouNeedToSave")}
              </Typography>
              <CenteredTextField
                className={classes.itemSpacing}
                isValid={validityIndex.currentAge}
                value={this.state.currentAgeText}
                label={getContent("howOldAreYouNowLabelText")}
                onChange={(newValue: string): void => {
                  this.setState({ currentAgeText: newValue });
                }}
              />
              <CenteredTextField
                className={classes.itemSpacing}
                explantion={getContent("howMuchIsInYourPensionNowExplanation")}
                value={this.state.currentPensionPotSizeText}
                isValid={validityIndex.currentPensionPotSize}
                label={getContent("howMuchIsInYourPensionNowLabelText")}
                onChange={(newValue: string): void => {
                  this.setState({ currentPensionPotSizeText: newValue });
                }}
              />
              {savingsPlanChartData && savingsPlanResults && (
                <>
                  {formatedTypography(
                    parse(
                      getContent("monthlySavingsExplanation"),
                      `[[${savingsPlanResults.monthlyPayment.toLocaleString("en")}]]`,
                    ),
                    {
                      variant: "h6",
                      className: [classes.sectionSpacing, classes.title].join(" "),
                    },
                  )}
                  <Chart
                    options={savingsPlanChartData.options}
                    series={savingsPlanChartData.series}
                    type="line"
                    width={deviceTypes.includes("mobile") ? "110%" : "600px"}
                    height={320}
                  />
                </>
              )}
              {/* Footer */}
              <Box
                className={classes.sectionSpacing}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <img alt="Profile of Wilhelm" className={classes.profilePic} src={profilePic}></img>
                <Box className={classes.item} textAlign="center" m={1}>
                  <Typography
                    variant="caption"
                    dangerouslySetInnerHTML={{
                      __html: parse(
                        getContent("aboutLabel"),
                        "<a href='https://twitter.com/wilhelmvdwalt' style='color: #FF1F5A'>",
                        "</a>",
                      ),
                    }}
                  ></Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(RetirmentPlanPage);
